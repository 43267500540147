<template>
  <div>
    <b-card>
      <filter-swapper
        v-if="!loading.first"
        :buttons="buttons"
        :controlHeightButtons="controlHeight"
        :trigger="selectedRows.length === 0"
      >
        <template #slot1>
          <form-render
            :key="keyFormRender"
            :fields="fields"
            :buttonSend="buttonSend"
            class="mb-2"
            :form.sync="formFilter"
            @send="filterList"
            containerButtonsClass="col-sm-12 col-md-8 col-lg-3 container-button mt-2"
          >
            <template #buttons>
              <b-button
                variant="outline-light"
                v-b-tooltip.hover
                title="Limpiar filtros"
                class="ml-2"
                @click="cleanFilter"
                ><feather-icon icon="RefreshCwIcon"
              /></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.first">
        <table-render
          id="table_folders"
          :schema="schema"
          :rows="rows"
          :onEdit="onEdit"
          :loading="loading.carriers"
          showCheckboxes="true"
          :selectedRows.sync="selectedRows"
        >
        <template #temporal_day="scope">
          <span v-if="editing !== scope.rowdata.id">{{ scope.rowdata.temporal_day || '---' }}</span>
          <b-form-input v-else v-model="formData.temporal_day" :type="'number'"/>
        </template>
        <template #vigencia="scope">
          <span v-if="editing !== scope.rowdata.id">{{ scope.rowdata.temporal_day || '---' }}</span>
          <form-render
            v-else
            :key="keyFormRenderCalendar"
            :fields="fieldsDataCalendar"
            :form.sync="formData"
          >
          </form-render>
        </template>

        <template #mundo="scope">
          <table-render-actions v-if="editing !== scope.rowdata.id" :actions="actions" :data="scope"></table-render-actions>
          <table-render-actions v-else :actions="actionsEdit" :data="scope"></table-render-actions>
        </template>
        </table-render>
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.first"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
    <modal-update-masive />
  </div>
</template>

<script>
import ModalUpdateMasive from './ModalUpdateMasive.vue'

export default {
  name: 'config-carriers',
  components: {
    ModalUpdateMasive
  },
  data() {
    return {
      profileFile: null,
      buttons: [],
      formFilter: {},
      selectedRows: [],
      payloadFilter: {},
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      schema: [],
      rows: [],
      fields: [],
      actions: [],
      actionsEdit: [],
      pagination: {
        page: 1,
        total: 40,
        limit: 10
      },
      formData : {},
      editing: null,
      loading: {
        first: false,
        carriers: false
      },
      keyFormRenderCalendar: 0,
      keyFormRender: 0,
      showingActions: false,
      maxMonth: new Date()
    }
  },
  watch: {
    editing(val) {
      console.log(this.rows[val])
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.maxMonth = this.maxMonth.setMonth(this.maxMonth.getMonth() + 2)
      this.schema = [
        { label: 'Origen', sortable: true, key: 'id' },
        { label: 'Destino', sortable: true, key: 'name' },
        { label: 'Dias tránsito base', sortable: true, key: 'country_carrier' },
        { label: 'Dias tránsito temporales', key: 'temporal_day', useSlot: true },
        { label: 'Rango de vigencia', key: 'vigencia', useSlot: true },
        { label: 'Acciones', key: 'mundo', useSlot: true, class: ['text-center']}
      ]
      this.fields = [
        { fieldType: 'FieldInput', name: 'destino', label: 'Destino', containerClass: 'col-md-4 container-info col-md-3' },
        { fieldType: 'FieldInput', name: 'origen', label: 'Origen', containerClass: 'col-md-4 container-info col-md-2' }
      ]
      this.fieldsDataCalendar = [
        { fieldType: 'FieldDatepicker', name: 'range_vigency', containerClass: 'col-sm-12 col-md-6 container-info', clearable: true, propsVCalendar: {'min-date': new Date(), 'max-date': this.maxMonth}, datePattern: '/', range: true, placeholder: 'dd/mm/yyyy' }
      ]
      this.buttons = [{ name: 'updateTransitMasive', text: 'Actualización Masiva', color: 'info', icon: 'Edit2Icon', action: this.updateTransitMasive }]
      this.actions = [{
        action: id => this.activeEditMode(id), icon: 'Edit2Icon', color: 'info', text: 'Editar'
      }]
      this.actionsEdit = [
        { action: (id) => this.saveEdit(id), icon: 'CheckIcon', color: 'success', text: 'Guardar' },
        { action: (id) => this.cancelEdit(id), icon: 'XIcon', color: 'danger', text: 'Cancelar' }
      ]

      this.rows = [
        {
          id: '1',
          name: 'DHL',
          country_carrier: 'DHL',
          status: 'Activo',
          temporal_day: '1'
        },
        {
          id: '2',
          name: 'Fedex',
          country_carrier: 'Fedex',
          status: 'Activo',
          temporal_day: '2'
        },
        {
          id: '3',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '3'
        },
        {
          id: '4',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '4'
        },
        {
          id: '5',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '5'
        },
        {
          id: '6',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '6'
        },
        {
          id: '7',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '7'
        },
        {
          id: '8',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '8'
        },
        {
          id: '9',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '9'
        },
        {
          id: '10',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '10'
        },
        {
          id: '11',
          name: 'Estafeta',
          country_carrier: 'Estafeta',
          status: 'Activo',
          temporal_day: '11'
        }
      ]
    },
    activeEditMode(id) {
      console.log(id)
      const index = this.rows.findIndex(row => row.id === id)
      //const CURRENT_ROW = this.rows.find(row => row.id === id)
      //console.log(CURRENT_ROW)
      this.editing = id
      this.formData = { ...this.rows[index]}
      //console.log(this.rows[index])
      //this.onEdit(id)
      this.keyFormRender++
      console.log(this.editing)
      console.log(this.rows[index])
    },
    onEdit(id) {
      console.log(id)
      //find index
      const index = this.rows.findIndex(row => row.id === id)
    },
    cancelEdit(id) {
      this.editing = null
      this.formData = {}
    },
    saveEdit(id) {
      console.log(this.formData)
    },
    filterList(form) {
      this.payloadFilter = {}
      Object.keys(form).map(key => {
        this.payloadFilter[key] = form[key].id || form[key]
      })
      if (this.pagination.page !== 1) this.pagination.page = 1; else this.getCarriers()
    },
    cleanFilter() {
      this.formFilter = {}
      this.keyFormRender++
      this.filterList(this.formFilter)
    },
    updateTransitMasive() {
      console.log('updateTransitMasive')
      this.openModal('modal-update-masive')
    },
    openModal(name) {
      this.$bvModal.show(name)
    }
  }
}
</script>

<style lang='scss'>
.carriers-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
</style>
