<template>
  <b-modal
    id="modal-update-masive"
    modal-class="dialog-800"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    class="modal-masive-update"
    @close="close"
  >
    <template #modal-title>
      <h3>Dias de transito temporales</h3>
    </template>
    <b-card>
    <form-render
      :fields="fields"
      :form.sync="form"
    >
    </form-render>
    </b-card>
  </b-modal>
</template>

<script>

export default {
  name: 'modal-update-masive',
  data() {
    return {
      form: {
      },
      fields: []
    }
  },
  mounted() {
    this.fields = [
      { fieldType: 'FieldInput', name: 'transit-day', label: 'Ingrese Nº de días que desea sumar o restar a los de tránsito de base.', containerClass: 'col-sm-12 container-info', type : 'number' },
      { fieldType: 'FieldDatepicker', name: 'range_vigency', containerClass: 'col-sm-12 col-md-6 container-info', clearable: true, propsVCalendar: {'min-date': new Date(), 'max-date': this.maxMonth}, datePattern: '/', range: true, placeholder: 'dd/mm/yyyy', useLabel : true, label: 'Rango de vigencia' }
    ]
  },
  methods: {
    uploadDataToUpdateFile () {
      if (!this.form.fileDataToUpdate) {
        this.$alert('Debes seleccionar un archivo para subir ')
        return
      }
      this.clearError()
      this.loading.uploadDataToUpdate = true
      const data = {
        file: this.form.fileDataToUpdate
      }
      this.baseService.callUploadFileCustomParams('updateMasiveManageBillingDataCompany', data)
        .then(response => {
          this.$success(this.$t('msg-exito-importar-archivo'))
          this.removeFileDataToUpdate()
          this.close()
        })
        .catch((err) => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
          if (err.errors) {
            err.errors.map((e) => {
              this.rows.push({ detail: e.detail })
            })
            this.fileWithError = true
          }
        })
        .finally(() => {
          this.loading.uploadDataToUpdate = false
        })
    },
    changeFileDataToUpdate(e) {
      this.form.fileDataToUpdate = e.target.files[0]
    },
    removeFileDataToUpdate() {
      this.form.fileDataToUpdate = null
      this.keyFormRender++
      this.clearError()
    },
    clearError() {
      if (this.fileWithError) {
        this.rows = []
        this.fileWithError = false
      }
    },
    close() {
      this.removeFileDataToUpdate()
      this.$bvModal.hide('modal-masive-update')
    }
  }
}
</script>

<style lang="scss" scoped>
  div.file-drop-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    max-height: 300px;
    overflow-y: auto;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding-bottom: 8px;
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .add-adition-invoices{
    width: 100%;
    span {
      width: 100% !important;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.8rem;
    }
  }
</style>
